const api_link = "https://newip.utrade.today/admin";
const access_key =
  "040ec1ee950ffc53291f6df0ffc30325-a1d41284cbad95a80f076eed39859381.77004ea213d5fc71acf74a8c9c6795fb";

export default class Php {
  load_dashboard(data) {
    return fetch(api_link + "/load_dashboard", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  reject_withdraw(data) {
    return fetch(api_link + "/reject_withdraw", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  accept_withdraw(data) {
    return fetch(api_link + "/accept_withdraw", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  change_default_brokerage(data) {
    return fetch(api_link + "/change_default_brokerage", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  change_default_multiplier(data) {
    return fetch(api_link + "/change_default_multiplier", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_admin_login(data) {
    return fetch(api_link + "/load_admin_login", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  change_password(data) {
    return fetch(api_link + "/change_password", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  edit_payment(data) {
    return fetch(api_link + "/edit_payment", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_deposit_withdraw(data) {
    return fetch(api_link + "/load_deposit_withdraw", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  delete_users(data) {
    return fetch(api_link + "/delete_users", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_payment(data) {
    return fetch(api_link + "/load_payment", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_profile(data) {
    return fetch(api_link + "/load_profile", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_withdraw(data) {
    return fetch(api_link + "/load_withdraw", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  reject_deposit(data) {
    return fetch(api_link + "/reject_deposit", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  accept_deposit(data) {
    return fetch(api_link + "/accept_deposit", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_master_brokerages(data) {
    return fetch(api_link + "/load_master_brokerages", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_deposit(data) {
    return fetch(api_link + "/load_deposit", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_super_multiplier(data) {
    return fetch(api_link + "/load_super_multiplier", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  edit_super_brokerage(data) {
    return fetch(api_link + "/edit_super_brokerage", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  manual_trade(data) {
    return fetch(api_link + "/manual_trade", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  reverse_transaction(data) {
    return fetch(api_link + "/reverse_transaction", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  delete_transaction(data) {
    return fetch(api_link + "/delete_transaction", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_supers_brokerages(data) {
    return fetch(api_link + "/load_supers_brokerages", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  edit_super(data) {
    return fetch(api_link + "/edit_super", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_supers_data(data) {
    return fetch(api_link + "/load_supers_data", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_admin_brokerages(data) {
    return fetch(api_link + "/load_admin_brokerages", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  add_super(data) {
    return fetch(api_link + "/add_super", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  change_supers_multiplier(data) {
    return fetch(api_link + "/change_supers_multiplier", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_admin_multiplier(data) {
    return fetch(api_link + "/load_admin_multiplier", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_super_overview(data) {
    return fetch(api_link + "/load_super_overview", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  change_supers_status(data) {
    return fetch(api_link + "/change_supers_status", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_supers_active_summary(data) {
    return fetch(api_link + "/load_supers_active_summary", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  change_supers_setting(data) {
    return fetch(api_link + "/change_supers_setting", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  unban_supers_script(data) {
    return fetch(api_link + "/unban_supers_script", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_supers_scripts(data) {
    return fetch(api_link + "/load_supers_scripts", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_supers_login(data) {
    return fetch(api_link + "/load_supers_login", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_supers_ledger(data) {
    return fetch(api_link + "/load_supers_ledger", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  change_masters_multiplier(data) {
    return fetch(api_link + "/change_masters_multiplier", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_masters_active_summary(data) {
    return fetch(api_link + "/load_masters_active_summary", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_brokers_summary(data) {
    return fetch(api_link + "/load_brokers_summary", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  add_master(data) {
    return fetch(api_link + "/add_master", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  edit_master(data) {
    return fetch(api_link + "/edit_master", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_masters_data(data) {
    return fetch(api_link + "/load_masters_data", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_masters_ledger(data) {
    return fetch(api_link + "/load_masters_ledger", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_masters_scripts(data) {
    return fetch(api_link + "/load_masters_scripts", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  change_masters_status(data) {
    return fetch(api_link + "/change_masters_status", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_master_overview(data) {
    return fetch(api_link + "/load_master_overview", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_super_brokerages(data) {
    return fetch(api_link + "/load_super_brokerages", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_masters_summary(data) {
    return fetch(api_link + "/load_masters_summary", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_notification(data) {
    return fetch(api_link + "/load_notification", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  deactive_admin_rules(data) {
    return fetch(api_link + "/deactive_admin_rules", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  add_admin_rules(data) {
    return fetch(api_link + "/add_admin_rules", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_admin_rules(data) {
    return fetch(api_link + "/load_admin_rules", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_brokers_admin(data) {
    return fetch(api_link + "/load_brokers_admin", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  users_sqoff_single(data) {
    return fetch(api_link + "/users_sqoff_single", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  get_all_mcx_scripts(data) {
    return fetch(api_link + "/get_all_mcx_scripts", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  reset_users_brokerages(data) {
    return fetch(api_link + "/reset_users_brokerages", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  edit_users_brokerages(data) {
    return fetch(api_link + "/edit_users_brokerages", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_users_summary_new(data) {
    return fetch(api_link + "/load_users_summary_new", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  get_four_segment(data) {
    return fetch(api_link + "/get_four_segment", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  get_all_fut_scripts(data) {
    return fetch(api_link + "/get_all_fut_scripts", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  pass_pending(data) {
    return fetch(api_link + "/pass_pending", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  cancel_users_pending(data) {
    return fetch(api_link + "/cancel_users_pending", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_brokers_clients(data) {
    return fetch(api_link + "/load_brokers_clients", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_brokers_login(data) {
    return fetch(api_link + "/load_brokers_login", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  edit_broker(data) {
    return fetch(api_link + "/edit_broker", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_brokers_data(data) {
    return fetch(api_link + "/load_brokers_data", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  edit_user(data) {
    return fetch(api_link + "/edit_user", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  change_supers_balance(data) {
    return fetch(api_link + "/change_supers_balance", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  change_masters_balance(data) {
    return fetch(api_link + "/change_masters_balance", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  delete_bill_send_entry(data) {
    return fetch(api_link + "/delete_bill_send_entry", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  bill_send_brokerage(data) {
    return fetch(api_link + "/bill_send_brokerage", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_bill_send_entry(data) {
    return fetch(api_link + "/load_bill_send_entry", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  edit_bill_send_entry(data) {
    return fetch(api_link + "/edit_bill_send_entry", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  add_bill_send_entry(data) {
    return fetch(api_link + "/add_bill_send_entry", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_bill_send(data) {
    return fetch(api_link + "/load_bill_send", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  add_admin_notification(data) {
    return fetch(api_link + "/add_admin_notification", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  deactive_admin_notification(data) {
    return fetch(api_link + "/deactive_admin_notification", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_admin_notification(data) {
    return fetch(api_link + "/load_admin_notification", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_brokers(data) {
    return fetch(api_link + "/load_brokers", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_master_brokers(data) {
    return fetch(api_link + "/load_master_brokers", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  search_script(data) {
    return fetch(api_link + "/search_script", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  add_script(data) {
    return fetch(api_link + "/add_script", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_masters(data) {
    return fetch(api_link + "/load_masters", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_admins_watchlist(data) {
    return fetch(api_link + "/load_admins_watchlist", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  change_masters_setting(data) {
    return fetch(api_link + "/change_masters_setting", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_transactions_history(data) {
    return fetch(api_link + "/load_transactions_history", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  delete_script(data) {
    return fetch(api_link + "/delete_script", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_broker_client_filter(data) {
    return fetch(api_link + "/load_broker_client_filter", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_broker_filter(data) {
    return fetch(api_link + "/load_broker_filter", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  add_broker(data) {
    return fetch(api_link + "/add_broker", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_brokers_overview(data) {
    return fetch(api_link + "/load_brokers_overview", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  change_brokers_status(data) {
    return fetch(api_link + "/change_brokers_status", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_users(data) {
    return fetch(api_link + "/load_users", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        load_users: "1",
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_live_transaction(data) {
    return fetch(api_link + "/load_live_transaction", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_users_data(data) {
    return fetch(api_link + "/load_users_data", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        load_users_data: "1",
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  edit_users_scripts(data) {
    return fetch(api_link + "/edit_users_scripts", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  ban_users_script(data) {
    return fetch(api_link + "/ban_users_script", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_users_rejection(data) {
    return fetch(api_link + "/load_users_rejection", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_users_ledger(data) {
    return fetch(api_link + "/load_users_ledger", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  change_mobile(data) {
    return fetch(api_link + "/change_mobile", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_mobile_no(data) {
    return fetch(api_link + "/load_mobile_no", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_masters_login(data) {
    return fetch(api_link + "/load_masters_login", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_admin_ledger(data) {
    return fetch(api_link + "/load_admin_ledger", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_users_login(data) {
    return fetch(api_link + "/load_users_login", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  unban_masters_script(data) {
    return fetch(api_link + "/unban_masters_script", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_supers_summary(data) {
    return fetch(api_link + "/load_supers_summary", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_super_brokerages(data) {
    return fetch(api_link + "/load_super_brokerages", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_supers_brokerages(data) {
    return fetch(api_link + "/load_supers_brokerages", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_super_multiplier(data) {
    return fetch(api_link + "/load_super_multiplier", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_supers(data) {
    return fetch(api_link + "/load_supers", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  unban_users_script(data) {
    return fetch(api_link + "/unban_users_script", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  ban_masters_script(data) {
    return fetch(api_link + "/ban_masters_script", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  reset_users_scripts(data) {
    return fetch(api_link + "/reset_users_scripts", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        reset_users_scripts: "1",
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  add_users_data(data) {
    return fetch(api_link + "/add_users_data", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        add_users_data: "1",
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  add_user(data) {
    return fetch(api_link + "/add_user", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        add_user: "1",
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_users_scripts(data) {
    return fetch(api_link + "/load_users_scripts", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        load_users_scripts: "1",
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_positions(data) {
    return fetch(api_link + "/load_positions", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        load_positions: "1",
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_transactions(data) {
    return fetch(api_link + "/load_transactions", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        load_transactions: "1",
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_pendings(data) {
    return fetch(api_link + "/load_pendings", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        load_pendings: "1",
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  change_users_setting(data) {
    return fetch(api_link + "/change_users_setting", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        change_users_setting: "1",
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  add_users_limit(data) {
    return fetch(api_link + "/add_users_limit", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        add_users_limit: "1",
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  change_users_multiplier(data) {
    return fetch(api_link + "/change_users_multiplier", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        change_users_multiplier: "1",
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  add_users_balance(data) {
    return fetch(api_link + "/add_users_balance", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        add_users_balance: "1",
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_users_overview(data) {
    return fetch(api_link + "/load_users_overview", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        load_users_overview: "1",
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  change_users_status(data) {
    return fetch(api_link + "/change_users_status", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  add_users_lock(data) {
    return fetch(api_link + "/add_users_lock", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  cancel_users_lock(data) {
    return fetch(api_link + "/cancel_users_lock", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  add_users_fatak(data) {
    return fetch(api_link + "/add_users_fatak", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  cancel_users_fatak(data) {
    return fetch(api_link + "/cancel_users_fatak", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_users_locks(data) {
    return fetch(api_link + "/load_users_locks", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_users_fatak(data) {
    return fetch(api_link + "/load_users_fatak", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_users_summary(data) {
    return fetch(api_link + "/load_users_summary", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_brokerage(data) {
    return fetch(api_link + "/load_brokerage", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_online_users(data) {
    return fetch(api_link + "/load_online_users", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_users_active_summary(data) {
    return fetch(api_link + "/load_users_active_summary", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_parking(data) {
    return fetch(api_link + "/load_parking", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  add_all_scripts(data) {
    return fetch(api_link + "/add_all_scripts", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  get_all_scripts(data) {
    return fetch(api_link + "/get_all_scripts", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_holdings(data) {
    return fetch(api_link + "/load_holdings", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_holding_script(data) {
    return fetch(api_link + "/load_holding_script", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }
}
